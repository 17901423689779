.entry-list-item .item-body .item-controls .ecency-vote-input svg {
  max-width: 16px;
  width: 16px;
  height: 16px !important;
  max-height: 16px;
}

.ecency-vote-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    background-color: transparent;
    -moz-appearance: textfield;
  }
}