@import "src/styles/vars_mixins";

.entry-votes-modal {
  .ecency-modal-body {
    padding-top: 0;
    padding-bottom: 10px;

    .voters-list {
      @include user-grid-list(1, 1, 2);
      margin-bottom: 20px;
      height: 440px;
      overflow: auto;

      .list-body {

        .list-item {
          .item-extra {
            display: flex;
            align-items: center;

            .separator {
              margin: 0 6px;
              width: 4px;
              height: 4px;
              border-radius: 4px;
              display: inline-flex;
              font-size: 14px;

              @include themify(day) {
                @apply bg-silver;
              ;
              }

              @include themify(night) {
                @apply bg-blue-metallic;
              }
            }
          }
        }
      }
    }

    .list-tools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;

      @media (min-width: $lg-break) {
        flex-direction: row;
      }

      .sorter {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @media (min-width: $lg-break) {
          margin-bottom: 0;
        }

        .label {
          margin-right: 4px;
        }
      }
    }
  }
}

.entry-votes {
  align-items: center;
  display: flex;
  @apply text-gray-steel;

  &:hover {
    @apply text-blue-dark-sky;
  }

  svg {
    height: 12px;
    width: 12px;
    opacity: 0.5;
    margin-right: 5px;
    margin-top: 2px;
  }

  .inner-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: $xxsm-break) {
      font-size: 12px;
      svg {
        padding: 0 3px 0 5px;
      }
    }

    &.no-data {
      cursor: default;
    }

    .heart-icon {
      display: flex;

      &.voted {
        svg {
          @apply fill-blue-dark-sky;
          @apply text-blue-dark-sky;
        }
      }

      &.vote-done {
        animation: heartbeat 1s ease-in-out forwards;

        svg {
          @apply fill-blue-dark-sky;
          @apply text-blue-dark-sky;
        }
      }
    }
  }
}
