@import "src/styles/vars_mixins";

.search-list-item {
  @include entry-list-item;
  margin-top: 0;
  margin-bottom: 20px;

  .item-controls {
    svg {
      height: 14px !important;
      margin-right: 4px;
      opacity: 0.6;
    }

    .result-payout, .result-votes, .result-replies {
      @apply text-gray-steel;
      display: flex;
      align-items: center;
    }

    .result-payout, .result-votes {
      margin-right: 15px;
    }

    .result-replies {
      svg {
        margin-top: 2px;
      }
    }
  }
}

.no-img {
  width: 45px;
}