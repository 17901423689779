@import "../../../styles/vars_mixins";

.login-modal {
  .ecency-modal-body {
    width: 100%;
    max-width: 390px;
    margin: auto;

    .dialog-header {
      text-align: center;
      margin-bottom: 20px;

      img {
        height: 80px;
        margin-bottom: 16px;
      }

      h2 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
      }
    }

    p {
      @apply text-gray-steel;
      text-align: center;
    }

    .user-list {
      position: relative;
      min-height: 100px;

      .user-list-header {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 14px;
        text-align: center;

        @include themify(day) {
          @apply text-gray-steel;
        }

        @include themify(night) {
          @apply text-blue-metallic;
        }
      }

      .user-list-body {
        overflow-y: auto;
        max-height: 300px;

        .user-list-item {

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .user-avatar {
            margin-right: 10px;
          }

          .username {
            font-weight: 500;
          }

          .space {
            flex-grow: 1;
          }

          .check-mark {
            @apply bg-blue-dark-sky;
            border-radius: 50%;
            height: 5px;
            left: -14px;
            position: absolute;
            top: calc(50% - 2.5px);
            width: 5px;
          }

          button {
            opacity: 0;
          }

          &:hover button {
              opacity: 100;
          }

          &.disabled {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }

    .login-form {
      margin: auto;

      .login-form-text {
        font-size: 14px;
      }
    }

    .hs-login, .kc-login {
      margin: 0 auto 16px auto;
      display: flex;
      justify-content: center;

      .hs-logo, .kc-logo {
        height: 14px;
        width: 14px;
        margin-right: 6px;
      }
    }

    .kc-login {
      .kc-logo {
        height: 16px;
      }
    }
  }
}

.google-recaptcha {
  margin: 16px 0px;
}