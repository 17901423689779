@import "src/styles/vars_mixins";

.drafts-modal {
  .modal-body {
    .dialog-content {
      min-height: 590px;

      .dialog-filter {
        height: 70px;
      }

      .drafts-list {
        margin-top: 7px;
        height: 520px;
        overflow: auto;
      }
    }
  }
}

.no-img {
  width: 45px;
}