@import "src/styles/vars_mixins";

.list-style-toggle {
  cursor: pointer;
  padding: 2px;
  display: none;

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: $md-break) {
    display: block;
  }

  @include themify(day) {
    @apply text-light-periwinkle;
  }

  @include themify(night) {
    @apply text-blue-metallic;
  }

  &.toggled {
    border-radius: 5px;
    @include themify(day) {
      @apply bg-light-periwinkle-100;
    }

    @include themify(night) {
      @apply bg-blue-metallic-10;
    }
  }
}

.viewLayouts {
  display: none;
  
  @media (min-width: $md-break) {
    display: flex;
  }
}

.feed-view {
  .dropdown-toggle {
    background: none;
    border: none;
    padding: 0;

    @include themify(day) {
      @apply text-light-periwinkle;
    }

    @include themify(night) {
      @apply text-blue-metallic;
    }

    &:hover {
      background: none;
    }
  }

  .dropdown-menu {
    left: -80px !important;
    top: 10px !important;

    @include themify(day) {
      @apply bg-light-periwinkle-100;
    }

    @include themify(night) {
      @apply bg-blue-metallic-10;
    }

    .dropdown-item {
      padding: 6px 12px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.dropdown-divider {
  border-bottom: 1px solid #ddd;

  @include themify(day) {
    @apply border-light-periwinkle;
  }

  @include themify(night) {
    @apply border-blue-metallic;
  }
}

.gridMenu {
  svg {
    margin-right: 8px;
  }
}