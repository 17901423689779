@import "../../../styles/vars_mixins";

.switch-language {
  .menu-list {
    max-height: 280px;
    overflow-y: auto;
  }
  .drop-down-menu-contributors {
    padding: 10px 0;
    text-align: center;

    @include themify(day) {
      @apply bg-light-300;
    }

    @include themify(night) {
      @apply bg-dark-200;
    }
  }
}
