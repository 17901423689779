@import "src/styles/vars_mixins";

.edit-history-dialog {
  .ecency-modal-body {
    padding: 0;

    .edit-history-dialog-content {
      height: 500px;
      overflow: auto;

      @apply border-[--border-color] border-t;

      &.loading {
        border-top-color: transparent !important;
      }

      label {
        margin: 0;
      }

      .version-list-sm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        @apply bg-light-300 dark:bg-gray-900;

        .diff-select {
          width: 55%;
          flex-shrink: 0;

          label {
            font-size: 0.9rem;
          }
        }

        select {
          font-size: 0.9rem;
        }
      }

      .version-list-lg {
        width: 220px;
        display: none;

        @include themify(day) {
          @apply bg-light-300;
        }

        @include themify(night) {
          @apply bg-gray-800;
        }

        .diff-select {
          padding-left: 40px;
          margin: 20px 0;
        }

        .version-list-item {
          display: grid;
          margin-bottom: 4px;
          grid-template-columns: 30px auto;
          grid-template-rows: 24px 24px;
          cursor: pointer;
          padding: 10px 0;
          border: 0;

          &.selected {
            .item-title {
              font-weight: 700;
            }

            @include themify(day) {
              @apply bg-blue-duck-egg;
            }

            @include themify(night) {
              @apply bg-blue-dusky;
            }
          }

          .item-icon,
          .item-title,
          .item-date {
            align-items: center;
            display: flex;
          }

          .item-icon {
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 1;
            grid-row-end: 4;
            justify-content: center;
            @apply text-gray-steel-010;

            svg {
              height: 20px;
            }
          }

          .item-title {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            font-weight: 400;
          }

          .item-date {
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            font-size: 90%;
          }
        }
      }

      .version-detail {
        width: 100%;
        padding: 10px 20px;

        .entry-title {
          font-size: 32px;
          margin: 0 0 22px 0;

          @include themify(day) {
            @apply text-gray-charcoal;
          }

          @include themify(night) {
            @apply text-gray-pinkish;
          }
        }

        .entry-tags {
          margin-bottom: 22px;
          @apply text-gray-steel;
          display: flex;
          align-items: center;

          svg {
            height: 16px;
            margin-right: 6px;
          }
        }

        .entry-body {
          * {
            cursor: default;
            pointer-events: none;
          }
        }
      }

      @media (min-width: $lg-break) {
        .version-list-sm {
          display: none;
        }

        .version-list-lg,
        .version-detail {
          height: 100%;
          overflow-x: auto;
          float: left;
        }

        .version-list-lg {
          display: block;
        }

        .version-detail {
          width: calc(100% - 220px);
        }
      }
    }
  }
}
