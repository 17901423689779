@import "src/styles/vars_mixins";

.entry-tip-btn {
  margin-top: -1px;
  margin-right: 15px;

  @media (min-width: $sm-break) {
    margin-right: 25px;
  }

  .inner-btn {
    cursor: pointer;
    align-items: center;
    @apply text-blue-dark-sky;
    display: flex;

    svg {
      height: 16px;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }
}
