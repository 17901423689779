@import "src/styles/vars_mixins";

// do not wrap this. transfer also works in other dialogs.

.transfer-dialog-content {

  .transaction-form-body.powering-down {
    text-align: center;
    @include themify(day) {
      @apply text-gray-steel;
    }

    @include themify(night) {
      @apply text-blue-metallic;
    }
  }

  .power-down-estimation, .balance {
    margin-top: 16px;
    font-weight: 300;
    padding-left: 20px;
  }

  .balance {
    margin: 1rem 0;
    font-weight: 300;
    padding-left: 20px;

    @media (min-width: $sm-break) {
      display: flex;
      align-items: center;
    }

    @include themify(day) {
      @apply text-gray-charcoal;
    }

    @include themify(night) {
      @apply text-gray-pinkish;
    }

    .balance-num {
      @apply text-blue-dark-sky;
      cursor: pointer;
      margin-left: 2px;
    }

    .balance-hp-hint {
      font-size: 90%;
      opacity: 0.8;


      @media (min-width: $sm-break) {
        margin-left: 10px;
        flex-grow: 1;
      }
    }
  }

  .power-down-estimation {
    @apply text-green;
  }

  .confirmation {
    margin-bottom: 30px;

    .confirm-title {
      @apply text-blue-dark-sky;
      font-size: 20px;
      font-weight: 500;
      margin: 10px 0;
      text-align: center;
    }

    .users {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;

      .from-user,
      .to-user,
      .arrow {
        height: 100px;
        width: 100px;
      }

      .from-user,
      .to-user {
        cursor: pointer;

        .user-avatar {
          height: 100%;
          width: 100%;
        }
      }

      .arrow {
        align-items: center;
        @apply text-silver;
        display: flex;
        justify-content: center;
        opacity: 0.4;

        svg {
          height: 60px;
          width: 60px;
        }
      }
    }

    .amount {
      @apply text-blue-dark-sky;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: center;
    }

    .amount-vests {
      @apply text-blue-dark-sky;
      text-align: center;
      margin-bottom: 20px;
    }

    .memo {
      @apply text-gray-warm;
      font-size: 16px;
      text-align: center;
      word-break: break-all;
    }
  }

  .success {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;

    @include themify(day) {
      @apply text-gray-charcoal;
    }

    @include themify(night) {
      @apply text-gray-pinkish;
    }

    .amount {
      @apply text-blue-dark-sky;
    }

    .from-user,
    .to-user {
      font-weight: 700;
    }
  }

  .asset-switch {
    flex-grow: 1;
    margin-left: 4px;

    .selected {
      border-width: 0px;
    }

    .asset {
      border-radius: 25px;
      padding: 3px 3px;
      font-size: 14px;
      cursor: pointer;

      @media (min-width: $sm-break) {
        padding: 3px 6px;
        font-size: 16px;
      }

      @include themify(day) {
        @apply text-gray-steel;
      }

      @include themify(night) {
        @apply text-silver;
      }

      &:not(:last-child) {
        margin-right: 4px;

        @media (min-width: $sm-break) {
          margin-right: 8px;
        }
      }

      &.selected {
        @apply bg-silver;

        @include themify(day) {
          @apply text-white;
        }

        @include themify(night) {
          @apply text-dark-default;
        }
      }
    }
  }

  .tr-form-text {
    padding-left: 20px;
  }

  .override-warning {
    font-size: 10px;
    margin-left: 20px;
  }

  .text-gray-600 {
    @apply text-gray-steel;
    @include themify(night) {
      @apply text-blue-metallic;
    }
  }
}
