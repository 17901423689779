@import "src/styles/vars_mixins";

.emoji-picker.gif {
  // width: 280px;
  width: 430px;
  position: absolute;
  right: 0;
  z-index: 12;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 14px 6px;

  @media (max-width: $sm-break) {
    width: 160px;
  }

  @include themify(day) {
    @apply bg-light-305;
  }

  @include themify(night) {
    @apply bg-dark-200;
  }

  .gif-cat-list {
    // height: 160px;
    height: 320px;
    overflow: auto;

    .gif-cat {
      .cat-title {
        font-weight: 500;
        font-size: 14px;
        margin: 4px 0 6px 0;

        @include themify(day) {
          @apply text-gray-charcoal;
        }

        @include themify(night) {
          @apply text-gray-pinkish;
        }
      }

      .gif-list {
        @include clearfix();
        display: flex;
        flex-wrap: wrap;

        .gifs {
          cursor: pointer;
          font-size: 18px;
          margin: 3px 3px 1px 3px;
          align-items: center;
          justify-content: center;
          display: flex;
          font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji";
          border-bottom: 2px solid transparent;
          img {
            width: 200px;
            height: 100px;
            object-fit: cover;
          }
          &:hover {
            @include themify(day) {
              border-bottom: 2px solid;
              @apply border-light-300;
            }

            @include themify(night) {
              border-bottom: 2px solid;
              @apply border-dark-600-010-light;
            }
          }
        }
      }
    }
  }
}


.gifData {
  display: inline-grid;
  grid: inline;
}