@import "src/styles/vars_mixins";

.bookmark-btn {
  cursor: pointer;
  @apply text-gray-steel-light-005;
  margin-right: 14px;

  @media (min-width: $sm-break) {
    margin-top: 3px;
  }

  svg {
    height: 20px;
  }

  &.bookmarked {
    @apply text-blue-dark-sky;
  }

  &.in-progress {
    pointer-events: none;
  }
}
