@import "src/styles/vars_mixins";

.new-feature {
  position: relative;
}

.video-upload-item {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    @include themify(day) {
      background-color: darken($white, 6);
    }

    @include themify(night) {
      background-color: lighten($dark, 6);
    }

  }
}

.new-feature::after {
  content: '';
  height: 0.35rem;
  width: 0.35rem;
  background: $red;
  position: absolute;
  top: -0.125rem;
  right: 0.35rem;
  border-radius: 1rem;
}

label {
  cursor: pointer;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .file-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .progresss {
    align-self: center;
    display: flex;
    position: relative;
    width: 80%;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: #fcfcfc;
    // background-color: gray;

    @include themify(day) {
      background-color: #c1c5c7;
    }

    @include themify(night) {
      background-color: #161d26;
    }

    .progress-bar {
      height: 20px;
      border-radius: 5px;
      background-color: #357ce6;
    }

    span {
      position: absolute;
      align-self: center;
      left: 40%;
      // right: 60%;
    }
  }
}

.video-successfull {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.three-speak-video-uploading {

  .video-source {
    display: flex;
    gap: 1rem;

    > div {
      width: 100%;
    }
  }
}

.video-upload-recorder {
  position: relative;

  .reset-btn {
    position: absolute;
    top: -2.65rem;
    right: 0;
  }

  .actions {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    z-index: 9;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .switch-camera {
      opacity: 0.75;
      cursor: pointer;
      color: $white;

      &:hover {
        opacity: 1;
      }
    }

    .record-btn {
      color: $danger;
      border: 0.25rem solid $white;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        border-color: var(--border-color);
      }
    }
  }

  video {
    width: 100%;
    border-radius: 1rem;
  }

  .no-permission {
    width: 100%;
    height: 300px;
    border-radius: 1rem;
    background-color: var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }
}