@import "../../../styles/vars_mixins";

.suggestion {
  position: relative;

  > :first-child {
    position: relative;
    z-index: 10;
  }

  .search-suggestion-list {

    .list-body {
      max-height: 200px;
      overflow-x: auto;
    }
  }

  .modal-suggestion-list {
    @apply w-full border border-[--border-color] rounded-2xl z-10 min-w-[200px] bg-white overflow-hidden;

    .list-body {
      max-height: 200px;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .list-item {
        padding: 8px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @include themify(day) {
          @apply text-gray-warm;

          &:hover,
          &:focus {
            @apply bg-blue-dark-sky-040;
            @apply text-blue-dark-sky;
          }
        }

        @include themify(night) {
          @apply text-silver;

          &:hover,
          &:focus {
            @apply text-white;
          }
        }
      }
    }
  }
}
