@import "src/styles/vars_mixins";

.emoji-picker-dialog {
  position: absolute;
  z-index: 201;

  em-emoji-picker {
    width: 300px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 0;
    top: unset !important;
    left: 0 !important;
    width: 100%;
    background: #fff;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--border-color);
    position: fixed;

    em-emoji-picker {
      width: 100%;
    }

    > div {
      width: 100%;
    }
  }

  @include themify(night) {
    background-color: rgba(21, 22, 23);
  }
}