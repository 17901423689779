@import "src/styles/vars_mixins";

.video-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

  .video-list-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 1rem;
    overflow: hidden;

    .thumnail-wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      cursor: pointer;
      background-color: $gray-100;

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
    }

    .list-details-wrapper {
      display: grid;
      grid-template-columns: 1fr min-content min-content;
      gap: 0.5rem;
      grid-template-areas:
        "status info copy"
        "title title title"
        "actions actions actions";
      width: 100%;
      padding: 0.75rem;

      &-title {
        grid-area: title;
      }

      &-info, &-copy {
        grid-area: info;
        opacity: 0.5;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 0.8;
        }

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      &-copy {
        grid-area: copy;
      }

      &-actions {
        grid-area: actions;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.25rem;
        margin-top: 1.5rem;
      }


      &-status {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.75rem;
        color: $gray-600;

        .status-icon-wrapper {

          .status-icon-encoded {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: $green;
          }

            .status-icon-published {
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              cursor: pointer;

              @apply bg-blue-dark-sky;
          }

          .status-icon-failed {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: rgb(199, 94, 94);
          }

          .status-icon-encoding {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: rgb(228, 144, 42);
          }

          .status-icon-deleted {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            background-color: $gray-400;
          }
        }
      }
    }
  }
}

.video-center {
  text-align: center;
  width: auto;
}

.video-info {
  padding: 0.5rem;
  @include themify(night) {
    color: $gray-500;
  }
  @include themify(day) {
    color: $gray-600;
  }
}

.video-status-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 0 1rem;
  font-size: 0.875rem;

  .custom-dropdown .dropdown-btn .label {
    font-size: 0.875rem;
  }

  .hint {
    border-radius: 1.15rem;
    line-height: 1.25;
    padding: 0.4rem .575rem;
    margin-left: auto;
  }

  .refresh-gallery svg {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
}

.more-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px;
  right: 20px;
  top: 10px;
  justify-content: space-around;
  width: max-content;
  height: 150px;
  border: 1px solid gray;
  border-radius: 5px;
  z-index: 999;

  @include themify(night) {
    background: #283241;
    border: 1px solid;
    @apply text-gray-pinkish;
    @apply border-dark-default;
  }

  @include themify(day) {
    background: $white;
    border: 1px solid;
    @apply bg-white;
    @apply text-gray-charcoal;
    @apply border-white-500;
  }
}

.video-gallery-modal {
  .modal-body {
    padding: 0;
  }

  .dialog-content {
    padding: 1rem;
  }
}
