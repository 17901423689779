@import "src/styles/vars_mixins";

.entry-share-dialog {
  .entry-share-modal-body {
    @media (max-width: 290px) {
      padding: 0.4rem;
    }
  }
  .share-buttons {
    display: grid;
    grid-template-columns: 60px 60px 60px 60px;
    grid-gap: 10px;
    width: 280px;
    margin: auto auto 20px auto;

    @media (max-width: 290px) {
      grid-gap: 2px;
    }

    .share-button {
      width: 60px;
      height: 60px;
      @apply bg-blue-dark-sky;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        @apply bg-blue-dark-sky-010;
      }

      svg {
        width: 28px;
        height: 28px;
        @apply text-white;
      }
    }
  }
}
