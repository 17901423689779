@import "src/styles/vars_mixins";
// TODO @import "../../features/decks/columns/helpers/helpers";

.entry-vote-btn {
  .btn-vote {
    @include btn-vote;
  }
}

.vote-modal {
  .modal-content {
    border: none;
    border-radius: 12px;
    padding: 6px;
  }
}

.tooltip-vote {
  position: relative;
  display: inline-block;
}

.tooltip-vote {
  @include compact_vote_slider();
}

.tooltip-vote .tooltiptext {
  width: 32vw;
  @apply rounded-xl bg-white text-dark-200 dark:bg-dark-200 dark:text-white border  border-[--border-color];
  text-align: center;
  padding: 10px;
  position: absolute;
  z-index: 2;
  left: -12px;
  top: -19px;
  white-space: pre-wrap;

  @media screen and (max-width: 1700px) {
    width: 50vw;
  }
  @media screen and (max-width: 1100px) {
    width: 65vw;
  }

  @media screen and (max-width: 560px) {
    width: 93vw;
    left: auto;
    right: auto;
    top: -23px;
  }

  @media screen and (max-width: 420px) {
    width: 91vw;
  }

  @media screen and (max-width: 375px) {
    width: 90vw;
  }

  @media screen and (max-width: 320px) {
    width: 88vw;
    .percentage {
      width: 60px !important;
    }
  }
}

.primary-btn {
  @apply text-blue-dark-sky bg-transparent;
}

.primary-btn-done {
  @include themify(night) {
    @apply text-white bg-blue-dark-sky;
  }

  @include themify(day) {
    @apply text-white bg-blue-dark-sky;
  }
}

.secondary-btn-done {
  @include themify(night) {
    @apply text-white;
    @apply bg-red-020;
  }

  @include themify(day) {
    @apply text-white;
    @apply bg-red-020;
  }
}

.vote-error {
  font-size: 12px;
  @apply text-red;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  p {
    margin: 0px;
  }

  @media screen and (max-width: 480px) {
    p {
      width: 90%;
      text-align: center;
      white-space: pre-wrap;
    }
  }
}

.no-rotate svg {
  transform: rotate(0deg) !important;
}

.vote-warning {
  font-size: 12px;
  @apply text-gray-600;
  display: flex;
  justify-content: center;
  margin-top: 8px;

  p {
    margin: 0px;
  }

  @media screen and (max-width: 480px) {
    p {
      width: 90%;
      text-align: center;
      white-space: pre-wrap;
    }
  }
}

.vote-remove {
  font-size: 12px;
  color: $yellow;
  display: flex;
  justify-content: center;
  margin-top: 8px;

  p {
    margin: 0px;
  }

  @media screen and (max-width: 480px) {
    p {
      width: 90%;
      text-align: center;
      white-space: pre-wrap;
    }
  }
}

.primary-btn-vote svg {
  transform: rotate(0deg) !important;
}

.reverse-range {
  transform: rotateY(180deg);
}

datalist {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 421px) {
    display: none;
  }
}

.error-message {
  cursor: default;
  display: block;
  line-height: 1.6;
}

.vote-error-suggestion {
  line-height: 2.5;
  display: flex;
  justify-content: center;

  .tipping-icon {
    margin-left: 15px;
    margin-top: 8px;
  }
}
