@import "src/styles/vars_mixins";

.info {
  @apply text-tundora;
  font-size: 36px;
  margin-bottom: 3rem;
}

.action {
  font-size: 22px;
  font-weight: 500;
}

.searchProfileInline {
  width: 50%;
  margin: 30px auto;
  display: flex;
  align-items: center;

  .search-box {
    position: relative;
    width: calc(100% - 120px);
  }

  .btnSearch {
    margin-left: 15px;
    width: 120px;
  }
}

.searchProfile {
  min-width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: $md-break) {
    input {
      width: 40% !important;
      transition: all 0.25s !important;
      &:focus {
            width: 100% !important;
      }
  }
  }

  .btnSearch {
    margin-top: 33px;
  }
}
