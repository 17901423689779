@import "../../../styles/vars_mixins";

.toggle-with-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @include border-radius(1rem);
  @include padding(0.5rem);

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: 0.3s;

    @include border-radius(0.5rem);
    @include padding(0.5rem);

    &:not(.selected):hover {
      cursor: pointer;
      box-shadow: 0 6px 20px -10px rgba(0, 0, 0, 0.25);
    }

    &.selected {
      background-color: $primary;
      @apply text-white;
    }
  }

  @include themify(day) {
    @apply bg-gray-100;
  }

  @include themify(night) {
    @apply bg-gray-800;
  }
}