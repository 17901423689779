@import "../../../styles/vars_mixins";

.user-avatar {
  border-radius: 50%;
  background: #ccc;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;

  &.xsmall {
    @include strict_size(16px, 16px);
  }

  &.small {
    @include strict_size(24px, 24px);
  }

  &.normal {
    @include strict_size(30px, 30px);
  }

  &.medium {
    @include strict_size(40px, 40px);
  }

  &.sLarge {
    @include strict_size(54px, 54px);
  }

  &.large {
    @include strict_size(80px, 80px);
  }

  &.xLarge {
    @include strict_size(120px, 120px);
  }

  &.deck-item {
    @include strict_size(35px, 35px);
  }
}
