@import "src/styles/vars_mixins";

.promote-dialog {
  .promote-dialog-content {
    .balance-input {
      @apply text-blue-dark-sky;
      font-weight: 500;
      cursor: default;

      &.is-invalid {
        color: $danger;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
