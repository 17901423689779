@import "src/styles/vars_mixins";

.entry-menu {
  display: flex;
  align-items: center;

  .custom-dropdown {
    .dropdown-btn {
    @apply text-gray-steel-light-005;

      &:hover {
        @apply text-blue-dark-sky;
      }
    }
  }

  .separated-share {
    .share-button {
      @apply text-gray-steel-light-005;
      cursor: pointer;
      margin-right: 14px;

      &:hover {
        @apply text-blue-dark-sky;
      }

      svg {
        height: 18px;
        width: 18px;
      }

      &.share-button-facebook, &.single-button {
        svg {
          margin-top: -1px;
          height: 17px;
          width: 17px;
        }
      }
    }

    .single-button {
      @media (min-width: $sm-break) {
        display: none;
      }
    }

    .all-buttons {
      display: none;
      @media (min-width: $sm-break) {
        display: flex;
      }
    }
  }
}
